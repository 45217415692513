import common from '../../assets/images/common.png'
import uncommon from '../../assets/images/uncommon.png'
import rare from '../../assets/images/rare.png'
import epic from '../../assets/images/epic.png'
import legendary from '../../assets/images/legendary.png'
import mythic from '../../assets/images/mythic.png'

import './index.css'

function Tiers(props:{
  attributes: number,
  supply: number,
}) {
  return(
    <div className='rarity'>
 

    </div>
  )
}

export default Tiers